<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h1 class="pb-5">Add new job title</h1>
      </div>
      <div class="cell small-8 medium-2">
        <router-link
          to="/admin/job-titles/">
          <button class="button expanded">
            All Job Titles
          </button>
        </router-link>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <form @submit.prevent="save">
          <label>Title
            <input
              aria-describedby="titleHelpText"
              name="title"
              placeholder="title"
              type="text"
              v-model="jobTitle">
          </label>
          <p
            class="help-text"
            id="nameHelpText">
            Please enter the job title
          </p>
          <div class="pt-3">
            <button class="button">
              Save Title
            </button>
          </div>
        </form>
      </div>
    </div>
    <app-message
      v-if="showMessage === true"
      :messageText="message"
      :link="link"
      :linkText="linkText"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Message from '@/components/messages/Message.vue';
import axios from '../../../axios';

export default {
  name: 'ClientsCreate',
  components: {
    appMessage: Message,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      jobTitle: '',
      link: '/admin/job-titles',
      linkText: 'View all job titles',
      message: '',
      showMessage: false,
    };
  },
  methods: {
    save() {
      const postData = {};
      postData.jobTitle = this.jobTitle;
      axios.post(`/jobTitles/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.message = response.data.message;
          this.showMessage = true;
          this.jobTitle = '';
        })
        .catch((error) => {
          console.log(error);
          this.message = 'Sorry the title was not created. Please try again.';
          this.showMessage = true;
        });
    },
  },
};
</script>
